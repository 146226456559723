const homeDefaultUrl = require('../assets/images/icon-home@3x.png');
const homeGreenUrl = require('../assets/images/icon-home-green@3x.png');

const accountDefaultUrl = require('../assets/images/icon-user@3x.png');
const accountGreenUrl = require('../assets/images/icon-user-green@3x.png');

const qrcodeDefaultUrl = require('../assets/images/icon-qrcode@3x.png');
const qrcodeGreenUrl = require('../assets/images/icon-qrcode-green@3x.png');

const scanDefaultUrl = require('../assets/images/icon-scan@3x.png');
const scanGreenUrl = require('../assets/images/icon-scan-green@3x.png');

const smartphoneDefaultUrl = require('../assets/images/icon-scan-smartphone@3x.png');
const smartphoneGreenUrl = require('../assets/images/icon-scan-smartphone-green@3x.png');

const terminalDefaultUrl = require('../assets/images/icon-scan-terminal@3x.png');
const terminalGreenUrl = require('../assets/images/icon-scan-terminal-green@3x.png');

const handshakeDefaultUrl = require('../assets/images/icon-handshake@3x.png');
const handshakeGreenUrl = require('../assets/images/icon-handshake-green@3x.png');

const accountInvoiceDefaultUrl = require('../assets/images/icon-account-invoice@3x.png');
const accountInvoiceGreenUrl = require('../assets/images/icon-account-invoice-green@3x.png');

const accountPersonalDataDefaultUrl = require('../assets/images/icon-account-personal-data@3x.png');
const accountPersonalDataGreenUrl = require('../assets/images/icon-account-personal-data-green@3x.png');

const accountEmailDefaultUrl = require('../assets/images/icon-account-email@3x.png');
const accountEmailGreenUrl = require('../assets/images/icon-account-email-green@3x.png');

const accountPhoneDefaultUrl = require('../assets/images/icon-account-phone@3x.png');
const accountPhoneGreenUrl = require('../assets/images/icon-account-phone-green@3x.png');

const accountPasswordDefaultUrl = require('../assets/images/icon-account-password@3x.png');
const accountPasswordGreenUrl = require('../assets/images/icon-account-password-green@3x.png');

const accountQrcodeDefaultUrl = require('../assets/images/icon-account-qrcode@3x.png');
const accountQrcodeGreenUrl = require('../assets/images/icon-account-qrcode-green@3x.png');

const accountPinDefaultUrl = require('../assets/images/icon-account-pin@3x.png');
const accountPinGreenUrl = require('../assets/images/icon-account-pin-green@3x.png');

const accountPaymentMethodsDefaultUrl = require('../assets/images/icon-account-payment-methods@3x.png');
const accountPaymentMethodsGreenUrl = require('../assets/images/icon-account-payment-methods-green@3x.png');

const accountPaymentMethodsCreditCardDefaultUrl = require('../assets/images/icon-credit-card@3x.png');
const accountPaymentMethodsCreditCardGreenUrl = require('../assets/images/icon-credit-card-green@3x.png');

const accountPaymentMethodsSepaDefaultUrl = require('../assets/images/icon-sepa@3x.png');
const accountPaymentMethodsSepaGreenUrl = require('../assets/images/icon-sepa-green@3x.png');

const accountAddressDefaultUrl = require('../assets/images/icon-account-branch-finder@3x.png');
const accountAddressGreenUrl = require('../assets/images/icon-account-branch-finder-green@3x.png');

const accountPaybackDefaultUrl = require('../assets/images/icon-account-payback@3x.png');
const accountPaybackGreenUrl = require('../assets/images/icon-account-payback@3x.png');

const accountCustomerCardDefaultUrl = require('../assets/images/icon-customer-card@3x.png');
const accountCustomerCardGreenUrl = require('../assets/images/icon-customer-card-green@3x.png');

const accountDeactivateDefaultUrl = require('../assets/images/icon-account-deactivate@3x.png');
const accountDeactivateGreenUrl = require('../assets/images/icon-account-deactivate-green@3x.png');
const accountDeactivateWhiteUrl = require('../assets/images/icon-account-deactivate-white@3x.png');

const accountLogoutDefaultUrl = require('../assets/images/icon-account-logout@3x.png');
const accountLogoutGreenUrl = require('../assets/images/icon-account-logout-green@3x.png');

const iconCorrectDefaultUrl = require('../assets/images/icon-correct@3x.png');
const iconCorrectGreenUrl = require('../assets/images/icon-correct-green@3x.png');

const accountGoogleMarkerDefaultUrl = require('../assets/images/icon-google-marker@3x.png');

const accountActionsListDefaultUrl = require('../assets/images/icon-list@3x.png');

const accountSearchDefaultUrl = require('../assets/images/icon-search@3x.png');

const accountCurrentLocationDefaultUrl = require('../assets/images/icon-current-location@3x.png');

const accountGoogleClusterM1 = require('../assets/images/google/m1.png');
const accountGoogleClusterM2 = require('../assets/images/google/m2.png');
const accountGoogleClusterM3 = require('../assets/images/google/m3.png');
const accountGoogleClusterM4 = require('../assets/images/google/m4.png');
const accountGoogleClusterM5 = require('../assets/images/google/m5.png');

const locationGoogleMarkerDefaultUrl = require('../assets/images/icon-google-marker-default@3x.png');

export default {
  iHome: {
    defaultUrl: homeDefaultUrl,
    greenUrl: homeGreenUrl,
  },
  iAccount: {
    defaultUrl: accountDefaultUrl,
    greenUrl: accountGreenUrl,
  },
  iQrcode: {
    defaultUrl: qrcodeDefaultUrl,
    greenUrl: qrcodeGreenUrl,
  },
  iScan: {
    defaultUrl: scanDefaultUrl,
    greenUrl: scanGreenUrl,
  },
  iSmartphone: {
    defaultUrl: smartphoneDefaultUrl,
    greenUrl: smartphoneGreenUrl,
  },
  iTerminal: {
    defaultUrl: terminalDefaultUrl,
    greenUrl: terminalGreenUrl,
  },
  iHandshake: {
    defaultUrl: handshakeDefaultUrl,
    greenUrl: handshakeGreenUrl,
  },
  iAccountInvoice: {
    defaultUrl: accountInvoiceDefaultUrl,
    greenUrl: accountInvoiceGreenUrl,
  },
  iAccountPersonalData: {
    defaultUrl: accountPersonalDataDefaultUrl,
    greenUrl: accountPersonalDataGreenUrl,
  },
  iAccountEmail: {
    defaultUrl: accountEmailDefaultUrl,
    greenUrl: accountEmailGreenUrl,
  },
  iAccountPhone: {
    defaultUrl: accountPhoneDefaultUrl,
    greenUrl: accountPhoneGreenUrl,
  },
  iAccountPassword: {
    defaultUrl: accountPasswordDefaultUrl,
    greenUrl: accountPasswordGreenUrl,
  },
  iAccountQrcode: {
    defaultUrl: accountQrcodeDefaultUrl,
    greenUrl: accountQrcodeGreenUrl,
  },
  iAccountPin: {
    defaultUrl: accountPinDefaultUrl,
    greenUrl: accountPinGreenUrl,
  },
  iAccountPaymentMethods: {
    defaultUrl: accountPaymentMethodsDefaultUrl,
    greenUrl: accountPaymentMethodsGreenUrl,
  },
  iAccountAddress: {
    defaultUrl: accountAddressDefaultUrl,
    greenUrl: accountAddressGreenUrl,
  },
  iAccountPayback: {
    defaultUrl: accountPaybackDefaultUrl,
    greenUrl: accountPaybackGreenUrl,
  },
  iAccountCustomerCard: {
    defaultUrl: accountCustomerCardDefaultUrl,
    greenUrl: accountCustomerCardGreenUrl,
  },
  iAccountDeactivate: {
    defaultUrl: accountDeactivateDefaultUrl,
    greenUrl: accountDeactivateGreenUrl,
    whiteUrl: accountDeactivateWhiteUrl,
  },
  iAccountLogout: {
    defaultUrl: accountLogoutDefaultUrl,
    greenUrl: accountLogoutGreenUrl,
  },
  iGoogleMarker: {
    defaultUrl: accountGoogleMarkerDefaultUrl,
  },
  iAccountList: {
    defaultUrl: accountActionsListDefaultUrl,
  },
  iAccountSearch: {
    defaultUrl: accountSearchDefaultUrl,
  },
  iAccountCurrentLocation: {
    defaultUrl: accountCurrentLocationDefaultUrl,
  },
  iGoogleClusterM1: {
    defaultUrl: accountGoogleClusterM1,
  },
  iGoogleClusterM2: {
    defaultUrl: accountGoogleClusterM2,
  },
  iGoogleClusterM3: {
    defaultUrl: accountGoogleClusterM3,
  },
  iGoogleClusterM4: {
    defaultUrl: accountGoogleClusterM4,
  },
  iGoogleClusterM5: {
    defaultUrl: accountGoogleClusterM5,
  },
  iLocationGoogleMarker: {
    defaultUrl: locationGoogleMarkerDefaultUrl,
  },
  iCreditCard: {
    defaultUrl: accountPaymentMethodsCreditCardDefaultUrl,
    greenUrl: accountPaymentMethodsCreditCardGreenUrl,
  },
  iSepa: {
    defaultUrl: accountPaymentMethodsSepaDefaultUrl,
    greenUrl: accountPaymentMethodsSepaGreenUrl,
  },
  iCorrect: {
    defaultUrl: iconCorrectDefaultUrl,
    greenUrl: iconCorrectGreenUrl,
  },
};
